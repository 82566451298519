import request from '@/utils/request'

// 分享集卡新建、编辑提交
export function subCollectingForm(data, type) { //type 1 新建 0编辑
    return request({
        url: "/customer-service/collectingCards",
        method: type == 1 ? 'post' : 'put',
        data
    })
}

// 根据Id获取分享集卡详情
export function getCollectingDetail(id) {
    return request({
        url: `/customer-service/collectingCards/${id}`,
        method: 'get'
    })
}

// 获取分享集卡列表
export function getCollectingList(data) {
    return request({
        url: "/customer-service/collectingCards/list",
        method: 'post',
        data
    })
}


// 分享集卡上下架
export function upDownShelfCollecting(id, type) { //type 1 上架 0 下架
    return request({
        url: type == 1 ? "/customer-service/collectingCards/upperShelf/" + id : "/customer-service/collectingCards/lowerShelf/" + id,
        method: 'put'
    })
}

// 删除分享集卡
export function deleteCollecting(id) {
    return request({
        url: "/customer-service/collectingCards/" + id,
        method: 'delete'
    })
}

//分享集卡查看用户列表
export function getCollectingPersonList(data) {
    return request({
        url: "/customer-service/collectingCards/getParticipantList",
        method: 'post',
        data
    })
}
