<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="活动名称" class="search-input" v-model="searchObj.name"></el-input>
          <el-select placeholder="活动状态" class="search-input ml-15" v-model="searchObj.status">
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-date-picker
            class="ml-15"
            v-model="searchObj.date"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
          ></el-date-picker>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link :to="{ name: 'Collecting_new' }">
                <el-button class="ml-15" type="primary">添加活动</el-button>
              </router-link>
            </div>
            <!-- <div class="flx-row ali-c">
              <el-button icon="el-icon-download" type="primary" plain>导入</el-button>
              <el-button icon="el-icon-upload2" class="ml-15" type="primary" plain>导出</el-button>
            </div>-->
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData" @selection-change="tableSelect">
            <!-- <el-table-column type="selection"></el-table-column> -->
            <el-table-column label="活动名称" prop="name" ></el-table-column>
            <!-- <el-table-column label="所属门店" prop="store"></el-table-column> -->
            <el-table-column label="活动时间" >
              <template slot-scope="scope">
                <span>{{scope.row.startTime}}至{{scope.row.endTime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="参与人数" prop="totalNumber" width="180"></el-table-column>
            <!-- <el-table-column label="奖品兑换数" prop="quantity"></el-table-column> -->
            <el-table-column label="活动状态">
              <template slot-scope="scope">
                <el-switch
                  @change="upDownShelf(scope.row.status, scope.row.id)"
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="operations">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link :to="{ name: 'Collecting_users', params: {id: scope.row.id} }">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-info"></i>
                      查看用户
                    </el-link>
                  </router-link>
                  <router-link :to="{ name: 'Collecting_new', query: {id: scope.row.id} }">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline ml-10"></i>
                      编辑
                    </el-link>
                  </router-link>
                  <span class="ml-10 tip-text-delete" @click="deleteCollectingAct(scope.row.id)">
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="最长连续签到天数" prop="longest"></el-table-column>
            <el-table-column label="获得积分" prop="inte"></el-table-column>
            <el-table-column label="获得奖品数量" prop="awards"></el-table-column>-->
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getCollectingList, upDownShelfCollecting, deleteCollecting } from "@/api/maketing/collecting"
export default {
  name: "collecting_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        name: "",
        status: "",
        date: []
      },
      //活动状态
      activity_status: [
        {
          id: 1,
          title: "有效"
        },
        {
          id: 2,
          title: "失效"
        }
      ],
      // 优惠券表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100]
    };
  },
  mounted() {
    this.initData();
    // this.search()
  },
  methods: {
    // 初始化数据
    initData() {
      // // console.log(this.searchObj.date);
      let data = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        startTime: this.searchObj.date[0] ? this.searchObj.date[0] : "",
        endTime: this.searchObj.date[1] ? this.searchObj.date[1] : "",
        status: this.searchObj.status,
        name: this.searchObj.name
      }
      getCollectingList(data).then(res => {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
      })
    },
    // 搜索事件
    search() {
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        name: "",
        status: "",
        date: []
      };
      this.initData();
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    upDownShelf(status, id) {
      upDownShelfCollecting(id, status).then(res => {
        this.$message({
          message: res.data.body.code == 500 ? res.data.body.msg : "操作成功",
          type: res.data.body.code == 500 ? "warning" : "success"
        });
        this.initData();
      });
    },
    deleteCollectingAct(id) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteCollecting(id).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.initData();
            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
</style>
